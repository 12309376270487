* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  height: 100%;
  background-color: #f8f8f8 !important;
  overflow: hidden;
  font-family: 'Lato';
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.leaflet-container {
  height: 100%;
  width: 100%;
}

.ScrollbarsCustom-TrackY {
  background-color: transparent !important;
  width: 8px !important;
  top: 0 !important;
  height: 100% !important;
  border-radius: 0 !important;
  margin-right: 7px;
}

.ScrollbarsCustom-ThumbY {
  width: 5px !important;
  background-color: #fff !important;
  transition: all 0.1s ease-in-out;
  margin-left: auto;
  border-radius: 0 !important;
}

.ScrollbarsCustom-TrackY:hover,
.ScrollbarsCustom-TrackY:hover .ScrollbarsCustom-ThumbY,
.ScrollbarsCustom-ThumbY.dragging {
  width: 8px !important;
}

.alert-success {
  color: #0f5132 !important;
  font-weight: 500;
  background-color: #c8efc8 !important;
}

.alert-success .Toastify__progress-bar {
  background-color: #62aa88;
}

.alert-success .Toastify__close-button {
  color: #0f5132;
}

.alert-danger {
  color: rgb(97, 26, 21) !important;
  font-weight: 500;
  background-color: #f8d7da !important;
}

.alert-danger .Toastify__progress-bar {
  background-color: #b08380;
}

.alert-danger .Toastify__close-button {
  color: rgb(97, 26, 21);
}

/* iframe {
  display: none;
} */
#gist-app {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
}
#gist-app iframe:not([allowfullscreen]) {
  display: none;
}